import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-intl";

import ContainerBox from "../components/containerBox";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { MainJumbotron } from "../components/jumbotron";

const useStyles = makeStyles(theme =>
  createStyles({
    head: {
      color: "white",
      fontWeight: 700,

      [theme.breakpoints.up("xs")]: {
        fontSize: "36px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "56px",
      },
    },
    lead: {
      fontSize: "14px",
      color: "white",
      fontWeight: 400,
    },

    content: {
      paddingTop: "160px",
      paddingBottom: "160px",
    },
    title: {
      color: "#222222",
      fontSize: "24px",
      fontWeight: 700,
      padding: "20px 27px",
      borderTop: "2px solid #858585",
      borderBottom: "1px solid #858585",
    },
    postWrapper: {
      padding: "40px 20px",
      borderBottom: "1px solid #858585",
    },
    date: {
      color: "#222222",
      marginBottom: "40px",
      fontSize: "16px",
    },
    post: {
      fontSize: "16px",
      color: "#858585",
    },
    buttonGroup: {
      marginTop: "40px",
      display: "flex",
      justifyContent: "space-between",
    },
    toListButton: {
      backgroundColor: "#858585",
      color: "#fff",

      "&:hover": {
        color: "#858585",
      },
    },
    link: {
      textDecoration: "none",
    },
  })
);

interface INewsPostProps {
  data: {
    ghostPost: {
      html: string;
      title: string;
      published_at_pretty: string;
    };
  };
  pageContext: {
    previous: {
      ghostId: string;
    };
    language: string;
  };
}

function NewsPost({ data, pageContext }: INewsPostProps) {
  const classes = useStyles();
  const { ghostPost } = data;
  const { previous, language } = pageContext;

  return (
    <Layout language={language}>
      <SEO title={ghostPost.title} />
      <MainJumbotron />
      <main className={classes.content}>
        <ContainerBox>
          <Typography variant="h1" className={classes.title}>
            {ghostPost.title}
          </Typography>

          <div className={classes.postWrapper}>
            <Typography component="p" className={classes.date} gutterBottom>
              {ghostPost.published_at_pretty}
            </Typography>
            <div
              className={classes.post}
              dangerouslySetInnerHTML={{ __html: ghostPost.html }}
            />
          </div>
          <div className={classes.buttonGroup}>
            {previous && (
              <Link
                to={`/company/news/${previous.ghostId}`}
                className={classes.link}
              >
                <Button variant="outlined">이전</Button>
              </Link>
            )}
            <Link to="/company/news" className={classes.link}>
              <Button className={classes.toListButton}>목록</Button>
            </Link>
          </div>
        </ContainerBox>
      </main>
    </Layout>
  );
}

export default NewsPost;

export const postQuery = graphql`
  query newsQuery($ghostId: String) {
    ghostPost(ghostId: { eq: $ghostId }) {
      html
      title
      published_at_pretty: published_at(formatString: "YYYY.MM.DD")
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    backgroundPlaceholder: file(
      relativePath: { eq: "ir/img-title-photo-ir@3x.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
